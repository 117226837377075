import React, { useContext } from "react";
import { Alert, Box } from "@mui/material";
import { FormContext } from ".";

export const FormAlert = () => {
  const { alertMessage } = useContext(FormContext);

  return alertMessage.message ? (
    <Box sx={{ margin: "10px" }}>
      <Alert
        sx={{
          display: "flex",
          justifyContent: "center",
          font: "normal normal 900 20px/20px Avenir",
        }}
        icon={false}
        severity={alertMessage.status === 200 ? "success" : "error"}
      >
        {alertMessage.message}
      </Alert>
    </Box>
  ) : null;
};
