export const formDistributorCatalog =(t, data, formik)=> [
    {
        type: "text",
        name: "code",
        label: "Code"
    },
    {
        type: "text",
        name: "name",
        label: "Name"
    },{
        type: "select",
        name: "country",
        label: "Country",
        options: data.Countries.map((country, i)=> ({
            id: country.id, value: country.id, label: country.name
        })),
        properties:{
            active: formik.values.id !== ""
        }
    },{
        type: "select",
        name: "isTesting",
        label: "Is testing",
        options: [
            {label: "true", value: 1},
            {label: "false", value: 0}
        ]
    }
];

export const FormApprovals = (data, formik) => [
    {
      type: "text",
      name: "email",
      label: "Email",
        properties: {
            active: formik.values.id !== "",
        },
    },
    {
      type: "select",
      name: "status",
      label: "Status",
      options: [
        { id: 0, value: 0, label: "Inactive" },
        { id: 1, value: 1, label: "Active" },
      ],
    },
    {
      type: "select",
      name: "country",
      label: "Country",
      options: data.Countries.map((country) => ({
        id: country.id,
        value: country.code,
        label: country.name,
      })),
      properties: {
        active: formik.values.id !== "",
      },
    },
    {
      type: "select",
      name: "user_type",
      label: "User Type",
      options: [
        { id: 0, value: "Surgeon", label: "Surgeon" },
        { id: 1, value: "Patient", label: "Patient" },
      ],
    },
    {
      type: "text",
      name: "description",
      label: "Description",
    },
  ];