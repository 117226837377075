import {initReactI18next} from "react-i18next";
import i18n from "i18next";

i18n.use(initReactI18next).init({
    lng: "en",
    interpolation: {escapeValue: false},
    resources: {
        en: {
            translation: {
                login: {
                    title: "Implants Customer Service",
                    loginAction: "Log in",
                },
                general: {
                    back: "Back",
                    cancel: "Cancel",
                    continue: "Continue",
                    copyText: "Copy Text",
                    filter: "Filter",
                    loading: "Loading!",
                    more: "See More",
                    no: "No",
                    saved: "Saved",
                    saving: "Saving!",
                    submit: "Submit",
                    update: "Update",
                    delete: "Delete",
                    upload: "Upload",
                    yes: "Yes",
                    UserCDC: "This account is not registered in Motiva Imagine DB.",
                    sendMail: "Send Email",
                    new: "New",
                    refresh: "Refresh",
                    add: "Add"
                },
                fileInfo: {
                    name: "Name",
                    size: "Size",
                },
                dialog: {
                    notAuthorized: {
                        title: "Unauthorized",
                        subtitle:
                            "Please contact support to obtain access to the application",
                    },
                },
                date: {
                    month: {
                        0: "January",
                        1: "February",
                        2: "March",
                        3: "April",
                        4: "May",
                        5: "June",
                        6: "July",
                        7: "August",
                        8: "September",
                        9: "October",
                        10: "November",
                        11: "December",
                    },
                },
                patient: {
                    container: {
                        title: "General",
                        shortcuts: "Shortcuts",
                    },
                    card: {
                        title: "User",
                        implants: {
                            title: "implant",
                            breast: {
                                title: "Breast Implants",
                            },
                            gluteal: {
                                title: "Gluteal Implants",
                            },
                        },
                    },
                    type: {
                        0: "General User",
                        1: "Patient",
                        2: "Doctor",
                    },
                    data: {
                        fullName: "Name",
                        firstName: "First Name",
                        lastName: "Last Name",
                        email: "Email",
                        country: "Country",
                        type: "Type",
                        source: 'Source',
                        provider: 'Provider'
                    },
                },
                surgery: {
                    title: "Surgery",
                    data: {
                        dateOfSurgery: "Date",
                        dateOfRegistration: "Registration Date",
                        surgeryCountry: "Country",
                        surgeonName: "Surgeon Name",
                        incisionKind: "Incision",
                        implantPlacement: "Placement",
                        indication: "Indication",
                    },
                },
                implantOrder: {
                    title: "Order",
                    data: {
                        date: "Date",
                        amount: "Amount",
                        transaction_id: "Transaction",
                    },
                },
                implantWarranty: {
                    title: "Warranty Extended",
                    action: {
                        extend: "Extend",
                    },
                    data: {
                        status: "Status",
                        isActive: "Active",
                        program: "Program",
                        startDate: "Start Date",
                        endDate: "End Date",
                        canBuyExtendedWarranty: "Can Purchase",
                        extendedWarrantyBuyExtendedRejectionReason: "Reason",
                        extendedDate: "Extend days Left?",
                        currentProgram: "Current Program",
                        startDateNew: 'Start Date',
                        validUntil: 'Valid Until',
                        coverage: 'Coverage',
                        motivaProgram: "Motiva Program",
                        warrantyEligible: 'What you’re eligible for',
                    },
                    program: {
                        0: 'Always Confident Warranty®',
                        1: 'First Year Free Coverage'
                    },
                    status: {
                        0: "No Warranty",
                        1: "Active",
                        2: "Expired",
                        3: "Inactive",
                    },
                    statusAdditionalWarranties: {
                        0: "Expired",
                        1: "Active",
                        2: "Not apply",
                        3: "Inactive",
                    },
                    reasonAdditionalWarranties: {
                        0: "It's over 2 years",
                        1: "It is in the period of 2 years",
                        2: "Country not apply",
                    },
                    purchaseRejectionReason: {
                        0: "Unknown",
                        1: "Gluteal",
                        2: "Not Applicable",
                        3: "Reconstruction",
                        4: "After 90 days",
                        5: "Already extended",
                        6: "Explanted",
                    },
                    additionalWarranties: 'Additional Warranties',
                },
                implant: {
                    title: "Implant",
                    subTitle: "Tracking status",
                    incisionKind: {
                        0: "Undefined",
                        1: "Inframammary",
                        2: "Mastectomy Scar",
                        3: "Periareolar",
                        4: "Transaxillary",
                        5: "Mastopexy",
                    },
                    placement: {
                        0: "Undefined",
                        1: "Subglandular",
                        2: "Partial sub-muscular/Dual Plane",
                        3: "Sub-fascial",
                        4: "Complete muscle coverage",
                    },
                    indication: {
                        0: "Undefined",
                        1: "Primary Augmentation",
                        2: "Revision Augmentation",
                        3: "Primary Reconstruction",
                        4: "Revision Reconstruction",
                    },
                    status: {
                        0: "Warehouse",
                        1: "Shipped to the clinic",
                        2: "Implanted",
                        3: "Explanted",
                        4: "Discarded",
                        5: "Lost",
                        6: "Temporary Explant By Lot",
                        7: "Implanted By Doctor"
                    },
                    type: {
                        B: "Breast",
                        G: "Gluteal",
                    },
                    side: {
                        L: "Left",
                        R: "Right",
                    },
                    source:{
                        0: "External Registry",
                        1: "PMS Complaint Data",
                        2: "Phone",
                        3: "Email",
                        4: "Fax",
                        5: "Paper DT Form",
                    },
                    data: {
                        validationCode: "Validation Code",
                        serialNumber: "Serial Number",
                        electronicSerialNumber: "Electronic Serial Number",
                        side: "Side",
                        isRegistered: "Registered",
                        dateOfSurgery: "Surgery Date",
                        reference: "Catalog Number",
                        explanted: "Explanted",
                        volume: "Volume",
                        projection: "Projection",
                        isActive: "Active",
                        surface: "Surface",
                        isBlocked: "Blocked",
                        isTracked: "Tracked",
                        isExplanted: "Explanted",
                        distributor_country_name: "Distributor Country",
                        deviceStatus : {
                            status: "Status",
                            reason: "Reason / Cause",
                            date: "Date",
                            source: "Source",
                            originalImplantDate: "Original implanting date",
                            originalImplantPhysician: "Original implanting physician",
                            deviceContributorRemoval: "Did the device cause or contribute to the reason for removal?",
                            surgeonName: "Explant surgeon name"
                        }
                    },
                },
                home: {
                    title: "Welcome to Motiva Dasboard",
                    subtitle: "What do you want to do today?",
                },
                action: {
                    extendWarranty: "Extend Warranty",
                    patientErrors: "User Errors",
                    ResendEmail: "Resend Email",
                    fullTransactionHistory: "Full Transaction History",
                    userEmailOrPassword: "Send Email Or Password",
                    sendWarrantyOrRegistrationEmail: "Send Warranty Or Registration Email",
                    search: "Search",
                    reports: "Reports",
                    users: "Users",
                    settings: "Settings",
                    home: "Home",
                    addUser: "Add User",
                    editUser: "Edit User",
                    deleteUser: "Delete",
                    approvals: "US Approvals",
                    maintenance: "Distributor Maintenance",
                },
                edit: {
                    save: "Save",
                    cancel: "Cancel",
                },
                patientErrors: {
                    title: "User Errors",
                    description: "Check where and what the user is having trouble with",
                    data: {
                        date: "Date",
                        code: "Code",
                        comment: "Comment",
                        process: "Process",
                    },
                    errors: {
                        200: "Unknown 200",
                        201: "L Implant vc is wrong",
                        202: "R Implant vc is wrong",
                        203: "L Implant is registered",
                        204: "R Implant is registered",
                        205: "L Implant does not exist",
                        206: "R Implant does not exist",
                        207: "L Implant is blocked",
                        208: "R Implant is blocked",
                        209: "Different sugery and implant",
                        210: "Same serial number",
                    },
                    process: {
                        R: "Registration",
                        W: "Extended Warranty",
                    },
                },
                fullTransactionHistory: {
                    title: "Transaction History",
                    data: {
                        type: "Type",
                        created: "Date",
                        distributor: "Distributor",
                        distributorCode: "Distributor Code",
                        country: "Country",
                    },
                    transaction: {
                        title: "Current Distributor Information",
                        distributorName: "Distributor Name",
                        businessPartner: "Business Partner",
                        country: "Country",
                        implantImportedAt: "Implant imported at",

                    }
                },
                sendEmailOrGeneratePassword: {
                    instructions: "Select option",
                },
                sendEmail: {
                    title: "Send Email",
                    confirm: {
                        title: "Email successfuly sent",
                        description:
                            "Alert the user to check their Inbox or Spam, copy the following text",
                        userAction: "Email successfuly sent, Check your Inbox or Spam",
                    },
                },
                reSendEmail: {
                    selectLanguage : "Select language",
                    copyAnotherEmail : "Do you want to copy another email?",
                    secondEmail : "Enter second email",
                    typeOfImplants: "Type of implants"
                },
                generatePassword: {
                    title: "Generate Password",
                    confirm: {
                        title: "Password Changed",
                        description: "Alert the user about their password change",
                        userAction:
                            "Your password has been changed to . To update it again go to https://motiva.health/motivaImagine",
                    },
                },
                extendWarranty: {
                    title: "Extend Warranty",
                    instructions:
                        "This will give the user the oportunity to purchase or extend their warranty",
                    instructionDays: "Enter how many days you want to add",
                    instructionDate: "Or enter the last day of the term",
                },
                search: {
                    title: "Search Implants",
                    messages: {
                        welcome: {
                            subtitle:
                                "Enter a serial number (SN), electronic serial number (ESN) or email and get all the information you need in one place",
                        },
                        notFound: {
                            title: "No results for your search",
                            subtitle:
                                "Please double check your spelling or try searching using a serial number",
                            addImplant: "Add Implant",
                            modal: {
                                title: "Add new implant",

                            }
                        },
                    },
                    criteria: {
                        email: "Search by Email",
                        serial: "Search by Serial Number",
                        eserial: "Search by Electronic Serial Number",
                    },
                    results: "Results for",
                },

                reports: {
                    instruction: "Select who you would like to make the report for",
                    customerCare: {
                        name: "Customer Care",
                        instruction:
                            "Upload your CSV file with a list of implants to verify their current status",
                        steps: {
                            1: "Upload csv with different serial numbers",
                            2: "We look for the data for each of the implants (date of surgery, the name of the doctor and if they are registered or not)",
                            3: "Download the report with all the data",
                        },
                        upload: {
                            instructions: "Drag and drop your .csv file here *",
                            chooseFileInstructions: "Choose File",
                            templateInstruction:
                                "Remember to have the SN in the first column or download the recommended csv template",
                            templateDisclaimer:
                                "* CSV only supports up to 4000 Serial Numbers/line items by report",
                            templateDownload: "here",
                        },
                    },
                    distributor: {
                        name: "Distributor",
                        instructions:
                            "Filter implants by country, distributor and dates for your report",
                        filter: {
                            label: "Filter By",
                            startDate: "Start Date",
                            endDate: "End Date",
                            country: "Country",
                            distributor: "Distributor",
                            error: {
                                countryRequired: "Country is required",
                            },
                        },
                    },
                    implants: {
                        name: "Implants",
                        data: {
                            serial: "Serial",
                            electronic_serial: "Electronic serial",
                            lot: "Lot",
                            startDate: "Start Date",
                            endDate: "End Date",
                            country: "Country",
                            distributor: "Distributor",
                            type: "Type",
                            family_type: "Family type",
                            is_registered: "Registered",
                            is_tracked: "Tracked",
                            not_blocked: "Not blocked",
                            is_q_inside: "Q Inside",
                            status: "Status",
                        },
                    },
                    deviceTracking: {
                        name: "Device Tracking",
                        titleHead:{
                            id: "id",
                            country: "Country",
                            doctorName: "Doctor name",
                            action: "Action"
                        },
                        titleDetail:{
                            id: "id",
                            serial: "Serial",
                            type: "Type",
                            surgeryDate: "Surgery date",
                            status: "Status"
                        },
                        filters: {
                            startDate: "Start Date",
                            endDate: "End Date",
                            country: "Country",
                            distributor: "Distributor",
                            status: "Status",
                        }
                    },
                    explantsInvestigation: {
                        name: "Explants investigation",
                        titleModal: "Serial number assignment",
                        send: "Send"
                    },
                    BIAnnual: {
                        name: "BI Annual Report",
                        1: "Implanted Devices: COUNT OF TYPE OF IMPLANT PER YEAR",
                        2: "Implanted Devices: PATIENT CODE WITH COUNT OF IMPLANTS PER YEAR",
                        3: "Explanted Devices: PATIENT CODE WITH COUNT OF IMPLANTS PER YEAR",
                        4: "Explanted Devices: COUNT OF TYPE OF IMPLANT PER YEAR",
                        5: "Currently Implanted: TYPE OF IMPLANT COUNT",
                        6: "Currently Implanted: IMPLANTED PATIENTS COUNT WITH TYPE OF IMPLANT",
                    }
                },

                user: {
                    activityLog: {
                        title: "User Activity",
                        load: "Load User Activity Logs",
                        data: {
                            userId: "User",
                            action: "Action",
                            description: "Description",
                            createdAt: "Date",
                        },
                    },
                    add: {title: "Add User"},
                    data: {
                        id: "Edit",
                        fullName: "Full Name",
                        roleId: "Role",
                        email: "E-mail",
                        department: "Department",
                        isActive: "Active",
                        delete: "Delete"
                    },
                    role: {
                        ROLE_NONE: "No role",
                        ROLE_VIEW: "Read Only",
                        ROLE_COMPLIANCE: "Compliance",
                        ROLE_SUPPORT: "Support",
                        ROLE_ADMIN: "Administrator",
                        ROLE_PMS: "PMS",
                        ROLE_US_APPROVAL: "US Approvals"
                    },
                    deleteModal: {
                        title: "Are you sure?",
                        body: "You're about to delete the user: {{name}} ({{email}})"
                    }
                },
                maintenance: {
                    title: "We're sorry!",
                    subTitle: "This page is down for maintenance.",
                    description: "We are working to get it back up and running as soon as possible.",
                    actionButton: "Please check back!"
                },
                settings: {
                    distributor: {
                        new: "New distributor",
                        edit: "Edit distributor",
                    }
                }
            },
        },
    },
});

export default i18n;
